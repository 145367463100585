import { token } from "@/constants/authorization";
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: "/",
      name: "signin",
      component: () => import("../views/sign-in.vue"),
      meta: { guest: true },
    },
    {
      path: "/signup",
      name: "signup",
      component: () => import("../views/sign-up.vue"),
      meta: { guest: true },
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("../views/settings.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("../views/dashboard.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/about",
      name: "about",
      component: () => import("../views/AboutView.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/dashboard/top-gainers-and-losers",
      name: "top-gainers-and-losers",
      component: () => import("../views/top-gainers-and-losers.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/dashboard/easyboard",
      name: "dashboard-easyboard",
      component: () => import("../views/easyboard.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/dashboard/easyboard/:id",
      name: "dashboard-easyboard-id",
      component: () => import("../views/easyboard.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/dashboard/:id",
      name: "SPY-QQQ",
      component: () => import("../views/SPY-QQQ.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "*",
      name: "Not-Found",
      component: () => <div>Page Not Found</div>,
    },
  ],
});
export default router;

router.beforeEach((to, from, next) => {
  const { user } = token();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (user.token) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const { user } = token();
  if (to.matched.some((record) => record.meta.guest)) {
    if (user.token) {
      next("/dashboard");
      return;
    }
    next();
  } else {
    next();
  }
});
