<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import axios from "axios";
import { token } from "./constants/authorization";
const { user } = token();
axios.defaults.headers.common["Authorization"] = `Bearer ${user.token || ""}`;
export default {
  name: "App",
};
</script>
