import Vue from "vue";
import App from "./App.vue";
import "./index.css";
import router from "./router";
import store from "./store";
import VueFormulate from "@braid/vue-formulate";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import "es6-promise/auto";
import { vfmPlugin } from "vue-final-modal";

/* add icons to the library */
library.add(fas);
/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueFormulate);
Vue.use(vfmPlugin);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
