import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    chartTimeFrame: localStorage.getItem("timeframe") || "1m",
    linesdata: {},
    sidebarInputFocused: false,
    modalInputFocused: true,
  },
  mutations: {
    setLinesdata(state, item) {
      console.log(item, "new item");
      state.linesdata = {
        ...state.linesdata,
        [item.symbol]: item.data,
      };
      console.log(state, "state");
    },
    updateSideBarInput(state, item) {
      state.sidebarInputFocused = item;
    },
    updateModalInput(state, item) {
      state.modalInputFocused = item;
    },
    updateLinesData(state, item) {
      let lines = {};
      item.forEach((obj) => {
        const data = obj.positions.map((line) => ({
          y: +line.price,
          color: line.color,
        }));
        lines = {
          ...lines,
          [obj.symbol]: [
            ...(lines[obj.symbol] || []),
            // ...(state.linesdata[obj.symbol] || []),
            ...data,
          ],
        };
      });
      // this.queueApiLoader = false;
      //console.log({ lines });
      state.linesdata = { ...lines };
      //console.log({ lines, state: state.linesdata });
    },
    resetLinesData(state) {
      state.linesdata = {};
    },
    updateChartTimeFrame(state, item) {
      state.chartTimeFrame = item;
    },
  },
  getters: {
    chartTimeFrame(state) {
      return state.chartTimeFrame;
    },
    sidebarInputFocused(state) {
      return state.sidebarInputFocused;
    },
    modalInputFocused(state) {
      return state.modalInputFocused;
    },
    linesdata(state) {
      return state.linesdata;
    },
  },
});
