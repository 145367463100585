export const token = () => {
  let user = localStorage.getItem("user");
  return {
    user: JSON.parse(user) || {},
  };
};

export const BASE_URL =
  process.env.VUE_APP_BASE_URL || "http://localhost:8788/api";

export const domainURL = "https://api-server.tradingeyes.app";

export const tokenForDomainURL = {
  Authorization: "Bearer 45xzqXe6xbDqzlM76zqvhlKmoX7nog4isicq3",
};
